/* eslint-disable @typescript-eslint/naming-convention */

export interface SetModalHeightEventParams {
  height: string;
  lockScroll?: boolean;
}

export const SET_MODAL_HEIGHT_EVENT = 'setheight';

export interface FinancingPlan {
  min_price: string;
  max_price: string;
  terms: Term[];
}

export interface Term {
  installments_count: number;
  apr: number;
  loan_type: AvailableLoanType.SplitPay | AvailableLoanType.Interest;
}

export interface CartShopifyMetadata {
  type: 'cart';
  min_price: string;
  max_price: string;
  price_per_term: string;
  eligible: boolean;
  number_of_payment_terms: number;
  full_price: string;
  financing_plans: FinancingPlan[];
  seller_id: string;
  installments_buyer_prequalification_enabled: boolean;
}

export interface CheckoutShopifyMetadata {
  type: 'checkout';
  min_price: string;
  max_price: string;
  price_per_term: string;
  eligible: boolean;
  number_of_payment_terms: number;
  full_price: string;
  financing_plans: FinancingPlan[];
  seller_id: string;
  installments_buyer_prequalification_enabled: boolean;
}

export enum AvailableLoanType {
  Interest = 'interest',
  SplitPay = 'split_pay',
  ZeroPercent = 'zero_percent',
}

export enum InstallmentsBannerType {
  Cart = 'cart',
  Product = 'product',
  Checkout = 'checkout',
}

export enum InstallmentsPrequalPageType {
  IntroPageLoaded = 'prequal_intro_page_loaded',
  AuthorizeLoaded = 'prequal_authorize_loaded',
  BuyerFormOverlayLoaded = 'prequal_buyer_form_overlay_loaded',
  ResultsPageLoading = 'prequal_results_page_loading',
  ResultsPageLoaded = 'prequal_results_page_loaded',
  ContinueToCheckoutClicked = 'prequal_continue_to_checkout_clicked',
}

export enum InstallmentsBannerContent {
  PayInFour = 'pay_in_4',
  AsLowAs = 'as_low_as',
  PayInFourAsLowAs = 'pay_in_4_or_as_low_as',
}

export enum BannerTemplateCodeSignature {
  Standard = 'standard',
  CustomizedByMerchant = 'customized_by_merchant',
}

export enum ModalType {
  SplitPay = 'split_pay',
  ZeroInterest = 'zero_interest',
  InterestOnly = 'interest_only',
  ZeroInterestOnly = 'zero_interest_only',
  Adaptive = 'adaptive',
  Ineligible = 'ineligible',
}

export enum ModalUserAction {
  Close = 'close',
  ContinueToCheckout = 'continue_to_checkout',
  CloseAuth = 'close_auth_modal',
}

export interface Variant {
  id: number;
  price_per_term: string;
  eligible: boolean;
  full_price: string;
  available: boolean;
}

export interface VariantModalDetails {
  idQuantityMapping: string;
  available: boolean;
}
export interface PriceRange {
  minPrice: string;
  maxPrice: string;
}

export interface ProductShopifyMetadata {
  type: 'product';
  variants: Variant[];
  max_price: string;
  min_price: string;
  financing_plans: FinancingPlan[];
  installments_buyer_prequalification_enabled: boolean;
  seller_id: string;
}

export type ShopifyMetadata =
  | CartShopifyMetadata
  | CheckoutShopifyMetadata
  | ProductShopifyMetadata;

export interface LoanTerms {
  repayment: string;
  repaymentFrequency: string;
  apr: number;
  numberRepayments: number;
}

export interface ModalText {
  subTitle: string;
  legalCopy: string;
}

export interface InstallmentPlan {
  // Needed to keep trailing zeroes to the right of decimal
  pricePerTerm: string;
  apr: number;
  numberOfPaymentTerms: number;
  loanType: AvailableLoanType.SplitPay | AvailableLoanType.Interest;
}

export interface ProductFormElement extends HTMLCollection {
  quantity?: HTMLInputElement;
}

export enum MinIneligibleMessageType {
  SplitPay = 'split_pay',
  Monthly = 'monthly',
}

/**
 * Legacy types are strictly used for hardcoded instances of the banner that merchants might have implemented
 * This is from before the split into shop-pay-banner and shop-pay-installments-banner,
 * and thus are considered a minimum working version of the installment banner
 */
export interface LegacyCartShopifyMetadata {
  type: 'cart';
  min_price: string;
  max_price: string;
  price: string;
  eligible: boolean;
  number_of_payment_terms: number;
  available_loan_types: AvailableLoanType[];
}

export interface LegacyVariant {
  id: number;
  price: string;
  eligible: boolean;
  available_loan_types: AvailableLoanType[];
  available: boolean;
}

export interface LegacyProductShopifyMetadata {
  // Some shops are breaking if we require the product type.
  // If no type is provided, assume it's legacy => product
  type?: 'product';
  variants: LegacyVariant[];
  max_price: string;
  min_price: string;
  number_of_payment_terms: number;
}

export interface SamplePlan extends InstallmentPlan {
  interest: string;
  totalPriceWithInterest: string;
}

export type LegacyShopifyMetadata =
  | LegacyCartShopifyMetadata
  | LegacyProductShopifyMetadata;

export type MessageEventData =
  | CloseModalEvent
  | BuyerSuccessEvent
  | PrequalSuccess
  | PrequalError
  | PrequalMissingInformation
  | PrequalReady
  | PrequalClose
  | ContinueToCheckout
  | ResizeIframe
  | Error;
export interface CloseModalEvent {
  type: 'close';
}

export interface BuyerSuccessEvent {
  type: 'prequal_buyer_upsert_successful';
}

interface PrequalSuccess {
  type: 'prequal_success';
}

interface PrequalError {
  type: 'prequal_error';
}

interface PrequalMissingInformation {
  type: 'prequal_missing_information';
}

interface PrequalReady {
  type: 'prequal_ready';
}

interface PrequalClose {
  type: 'close';
}

interface ContinueToCheckout {
  type: 'continue_to_checkout';
}

interface ResizeIframe {
  type: 'resize_iframe';
  height: number;
  width: number;
}
interface Error {
  type: 'error';
}

export interface FontFaceDto {
  src: string;
  fontFamily: string;
  fontFaceDescriptors: FontFaceDescriptors;
}

export interface TextStyleDto {
  fontFamily: string;
  fontSize: string;
  color: string;
  letterSpacing: string;
  fontFace: FontFaceDto[];
}

import {I18n} from '../../../common/translator/i18n';
import {ShopLogo} from '../../../common/svg';
import Bugsnag from '../../../common/bugsnag';

export class LoginWithShopButton extends HTMLElement {
  #rootElement: ShadowRoot | null = null;
  #i18n: I18n | null = null;

  constructor() {
    super();

    if (!customElements.get('shop-logo')) {
      customElements.define('shop-logo', ShopLogo);
    }
  }

  async connectedCallback(): Promise<void> {
    await this.#initTranslations();

    const template = document.createElement('template');
    template.innerHTML = getTemplateContents();

    this.#rootElement = this.attachShadow({mode: 'open'});
    this.#rootElement.appendChild(template.content.cloneNode(true));

    if (this.#i18n) {
      const loginText = this.#i18n.translate('login_with_shop.login', {
        shop: getShopLogoHtml('white'),
      });
      this.#rootElement.querySelector('slot[name="login-text"]')!.innerHTML =
        loginText;
    }
  }

  async #initTranslations() {
    try {
      // BUILD_LOCALE is used for generating localized bundles.
      // See ./scripts/i18n-dynamic-import-replacer-rollup.mjs for more info.
      // eslint-disable-next-line no-process-env
      const locale = process.env.BUILD_LOCALE || I18n.getDefaultLanguage();
      const dictionary = await import(`../translations/${locale}.json`);
      this.#i18n = new I18n({[locale]: dictionary});
    } catch (error) {
      if (error instanceof Error) {
        Bugsnag.notify(error);
      }
    }
    return null;
  }

  setFocused() {
    this.#rootElement?.querySelector('button')?.focus();
  }
}

if (!customElements.get('login-with-shop-button')) {
  customElements.define('login-with-shop-button', LoginWithShopButton);
}

/**
 * Get the template contents for the Login with Shop trigger button.
 * @returns {string} string The template for the Login with Shop trigger button
 */
function getTemplateContents() {
  return `
    <style>
      :host {
        display: inline-block;
        line-height: normal;
      }

      /* TODO: Centralize styling of shop branded buttons: https://github.com/Shopify/shop-identity/issues/1252 */
      .button {
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        overflow: visible;

        background: #5433EB;
        border-radius: var(--buttons-radius, 4px);
        color: #ffffff;
        font: 16px/19px -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica;

        line-height: normal;

        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;

        -webkit-appearance: none;
        position: relative;
        display: flex;
        align-items: center;

        transition: background-color 100ms ease-in-out, opacity 100ms ease-out, color 100ms ease-in-out;
      }

      .button:hover {
        background: #7f68e9;
      }

      .login-text {
        white-space: nowrap;
        padding: 14px 45px;
        cursor: pointer;
      }

      .login-text shop-logo {
        position: relative;
        top: 3px;
      }
    </style>
    <button class="button">
      <span class="login-text">
        <slot name="login-text">
          Login with ${getShopLogoHtml('white')}
        </slot>
      </span>
    </button>
  `;
}

/**
 * helper function to create a Login with Shop trigger button
 * @param {string} color The color of the Shop logo.
 * @returns {string} The HTML for the Shop logo in the Login with Shop button.
 */
export function getShopLogoHtml(color: string): string {
  return `<shop-logo role="img" color=${color} size="15" style="display: inline-flex;"></shop-logo>`;
}

/**
 * helper function for building a Login with Shop Button
 * @returns {LoginWithShopButton} - a Login with Shop Button
 */
export function createLoginButton(): LoginWithShopButton {
  const element = document.createElement('login-with-shop-button');
  return element as LoginWithShopButton;
}

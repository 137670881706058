import {recordOpentel, TelemetryMetricId} from 'dynamicImports/opentelemetry';

import {extractSpinUrlSuffix} from './utils';

const SHOP_SPIN_DOMAIN = 'spin.dev';
const AUTH_DOMAIN_LOCAL = 'https://shopapp.myshopify.io';
const AUTH_DOMAIN_SPIN_PREFIX = 'https://shop-server';
const AUTH_DOMAIN_STAGING = 'https://pay-staging2.shopifycloud.com';
const AUTH_DOMAIN_PROD = 'https://shop.app';

/**
 * Returns the Pay domain to use for the Authorize call, based on the context in
 * which shop-js is running. If on spin - points to Spin Pay. If local - points
 * to local Pay. If in prod - points to Prod Pay.
 * @returns {string} The Pay domain to use for the Authorize call
 */
function getPayAuthDomain(): string {
  // Gets set at build time by rollup-replace
  // eslint-disable-next-line no-process-env
  switch (process.env.NODE_ENV) {
    case 'development':
      return AUTH_DOMAIN_LOCAL;
    case 'spin':
      return `${AUTH_DOMAIN_SPIN_PREFIX}${extractSpinUrlSuffix(SHOP_SPIN_DOMAIN)}`;
    case 'staging':
      return AUTH_DOMAIN_STAGING;
    case 'production':
    default:
      return AUTH_DOMAIN_PROD;
  }
}

const AUTH_DOMAIN_LOCAL_ALT = 'https://pay.myshopify.io';
const AUTH_DOMAIN_SPIN_PREFIX_ALT = 'https://pay-shopify-com';
const AUTH_DOMAIN_STAGING_ALT = 'https://pay-staging2.shopifycloud.com';
const AUTH_DOMAIN_PROD_ALT = 'https://pay.shopify.com';

/**
 * Returns the Pay domain alternate to use for the Authorize call, based on the context in
 * which shop-js is running. If on spin - points to Spin Pay. If local - points to local Pay.
 * If in prod - points to Prod Pay. The alternate domain is added to the iframe listener for
 * auth V2, as we are returning this alternate domain in order to support passkeys.
 * @returns {string} The Pay domain to use for the Authorize call
 */
function getPayAuthDomainAlt(): string {
  // Gets set at build time by rollup-replace
  // eslint-disable-next-line no-process-env
  switch (process.env.NODE_ENV) {
    case 'development':
      return AUTH_DOMAIN_LOCAL_ALT;
    case 'spin':
      return `${AUTH_DOMAIN_SPIN_PREFIX_ALT}${extractSpinUrlSuffix(SHOP_SPIN_DOMAIN)}`;
    case 'staging':
      return AUTH_DOMAIN_STAGING_ALT;
    case 'production':
    default:
      return AUTH_DOMAIN_PROD_ALT;
  }
}

const SHOP_WEBSITE_DOMAIN_LOCAL = 'https://arrive-website.myshopify.io';
const SHOP_WEBSITE_DOMAIN_SPIN_PREFIX = 'https://arrive-website';
const SHOP_WEBSITE_DOMAIN_STAGING = 'https://staging2.tryarrive.com';
const SHOP_WEBSITE_DOMAIN_PROD = 'https://shop.app';

/**
 * Returns the Shop Website domain based on the context in which shop-js
 * is running.
 * @returns {string} The Shop Website domain to use for the context.
 */
function getShopWebsiteDomain(): string {
  // Gets set at build time by rollup-replace
  // eslint-disable-next-line no-process-env
  switch (process.env.NODE_ENV) {
    case 'development':
      return SHOP_WEBSITE_DOMAIN_LOCAL;
    case 'spin':
      return `${SHOP_WEBSITE_DOMAIN_SPIN_PREFIX}${extractSpinUrlSuffix(SHOP_SPIN_DOMAIN)}`;
    case 'staging':
      return SHOP_WEBSITE_DOMAIN_STAGING;
    case 'production':
    default:
      return SHOP_WEBSITE_DOMAIN_PROD;
  }
}

export const PAY_AUTH_DOMAIN = getPayAuthDomain();
export const PAY_AUTH_DOMAIN_ALT = getPayAuthDomainAlt();
export const SHOP_WEBSITE_DOMAIN = getShopWebsiteDomain();

export enum InvalidUrlError {
  UsingLocalhost = 'using_localhost',
  NotUsingHttps = 'not_using_https',
  HasPath = 'has_path',
  HasHash = 'has_hash',
  HasSearch = 'has_search',
  InvalidUrl = 'invalid_url',
}

/**
 * Checks if the storefront origin is valid.
 * @param {string} storefrontOrigin - The storefront origin to validate.
 * @returns {boolean} True if the storefront origin is valid, false otherwise.
 */
export function validateStorefrontOrigin(storefrontOrigin: string): boolean {
  let error;
  try {
    const url = new URL(storefrontOrigin);
    if (
      (url.hostname === 'localhost' || url.hostname === '127.0.0.1') &&
      url.protocol !== 'https:'
    ) {
      error = InvalidUrlError.UsingLocalhost;
    } else if (url.protocol !== 'https:') {
      error = InvalidUrlError.NotUsingHttps;
    } else if (url.pathname !== '/') {
      error = InvalidUrlError.HasPath;
    } else if (url.hash) {
      error = InvalidUrlError.HasHash;
    } else if (url.search) {
      error = InvalidUrlError.HasSearch;
    }
  } catch (_) {
    error = InvalidUrlError.InvalidUrl;
  } finally {
    if (error) {
      // eslint-disable-next-line no-console
      console.error(`[shop-js] Invalid storefront origin: ${storefrontOrigin}`);
      recordOpentel(TelemetryMetricId.InvalidStorefrontOrigin, 1, {
        error,
      });
    }
  }

  return error === undefined;
}

import {OAuthParams, ShopActionType} from '../../../types';
import {extractSpinUrlSuffix} from '../../../common/utils';
import {I18n} from '../../../common/translator/i18n';
import {booleanQueryParam} from '../../../common/utils/booleanQueryParam';

const AUTH_PATH_CORE = '/services/login_with_shop/authorize';
const AUTH_DOMAIN_LOCAL_CORE = 'https://shop1.myshopify.io';
const AUTH_DOMAIN_SPIN_PREFIX_CORE = 'https://shop1.shopify';
const AUTH_DOMAIN_PROD_CORE = window.location.origin;

/**
 * Returns the Shopify Core domain to use for the Authorize call, based on the context in
 * which shop-js is running. This is an alternative to getAuthDomain() to be used for
 * Shop Login M2.
 * @returns {string} The Shopify Core domain to use for the Authorize call
 */
function getAuthDomainCore() {
  // Gets set at build time by rollup-replace
  // eslint-disable-next-line no-process-env
  const nodeEnv = process.env.NODE_ENV;

  switch (nodeEnv) {
    case 'development':
      return AUTH_DOMAIN_LOCAL_CORE;
    case 'spin':
      return `${AUTH_DOMAIN_SPIN_PREFIX_CORE}${extractSpinUrlSuffix()}`;
    case 'staging':
    case 'production':
    default:
      return AUTH_DOMAIN_PROD_CORE;
  }
}

const AUTH_DOMAIN_CORE = getAuthDomainCore();

/**
 * Build the Authorize URL that is proxied through Core. URL examples taken
 * from this PR: https://github.com/Shopify/shop-js/pull/270/files
 * @param {object} params The root parameter object.
 * @param {string} params.analyticsTraceId Links analytics events together in one flow.
 * @param {string} params.analyticsContext Defines the context in which this authorize flow is used (i.e.
 * "loginWithShopClassicCustomerAccounts" or "loginWithShopSelfServe") to differentiate events when the same
 * flow is used in different contexts.
 * @param {boolean} params.isCompactLayout If `true`, Pay will show the compact layout.
 * @param {boolean} params.isFullView Set to true to show the full login form, false to show the login button only.
 * @param {string} params.flow The flow to use for the login.
 * @param {string} params.flowVersion The version of the Sign in with Shop flow (eg. "sign_in" or "sign_up").
 * @param {boolean} params.emailVerificationRequired If `true` Pay will only respond with email verified users.
 * Otherwise Pay will respond with all users.
 * @param {boolean} params.signUpEnabled If `false` and Pay does not find a user with the given email address, it will
 * not show the Sign Up flow. Use when doing headless user lookups.
 * @param {boolean} params.avoidSdkSession Passed to Core to tell it to avoid the bounce to sdk-session and go straight
 * to sdk-authorize. Use this if you're confident it's not possible for a user to have an existing Pay session to avoid
 * the extra hop for performance.
 * @param {boolean} params.hideCopy If `true`, Pay will not show any copy around the ULC.
 * @param {boolean} params.modalCustomized If `true`, Pay will verify the client is permitted to customize the modal.
 * @param {string} params.apiKey The app's api key.
 * @param {boolean} params.consentChallenge If `true`, Pay will present the user with the personalization consent
 * challenge, if the feature is on and the user hasn't previously consented.
 * @param {string} params.checkoutVersion Checkout version for analytics.
 * @param {string} params.checkoutToken Checkout token for analytics.
 * @param {string} params.transactionParams Additional data sent to Shop Server for the checkout modal use case.
 * @param {string} params.shopId Shop ID
 * @param {boolean} params.requireVerification If `true`, Pay will not render the 1-tap flow cookied users
 * @param {OAuthParams} params.oauthParams The OAuth params for OAuth flows (such as code or implicit flow). Note that for the
 * implicit flow, the only required attribute in oauthParams is the clientId.
 * @returns {string} The Authorize URL in Shopify Core
 */
export const buildCoreAuthorizeUrl = ({
  analyticsTraceId,
  analyticsContext,
  isCompactLayout,
  isFullView,
  flow,
  flowVersion,
  emailVerificationRequired,
  signUpEnabled,
  avoidSdkSession,
  hideCopy,
  modalCustomized,
  apiKey,
  consentChallenge,
  checkoutVersion,
  checkoutToken,
  transactionParams,
  shopId,
  requireVerification,
  oauthParams,
}: {
  analyticsTraceId?: string;
  analyticsContext?: string;
  isCompactLayout?: boolean;
  isFullView?: boolean;
  flow?: ShopActionType;
  flowVersion?: string;
  emailVerificationRequired?: boolean;
  signUpEnabled?: boolean;
  avoidSdkSession?: boolean;
  hideCopy?: boolean;
  modalCustomized?: boolean;
  apiKey?: string;
  consentChallenge?: boolean;
  checkoutVersion?: string;
  checkoutToken?: string;
  transactionParams?: string;
  shopId?: string;
  requireVerification?: boolean;
  oauthParams?: OAuthParams;
}): string => {
  /* eslint-disable @typescript-eslint/naming-convention */
  const params = new URLSearchParams({
    target_origin: window.location.origin,
    // Should probably be optional: https://github.com/Shopify/shop-identity/issues/553
    api_key: apiKey ?? '123',
    // BUILD_LOCALE is used for generating localized bundles.
    // See ./scripts/i18n-dynamic-import-replacer-rollup.mjs for more info.
    // eslint-disable-next-line no-process-env
    locale: process.env.BUILD_LOCALE || I18n.getDefaultLanguage(),
    ...(analyticsTraceId && {analytics_trace_id: analyticsTraceId}),
    ...(analyticsContext && {analytics_context: analyticsContext}),
    ...booleanQueryParam('compact_layout', isCompactLayout),
    ...(flow && {flow}),
    ...(flowVersion && {flow_version: flowVersion}),
    ...(checkoutVersion && {checkout_version: checkoutVersion}),
    ...(checkoutToken && {checkout_token: checkoutToken}),
    ...(transactionParams && {transaction_params: transactionParams}),
    ...(shopId && {shop_id: shopId}),
    ...booleanQueryParam('full_view', isFullView),
    ...booleanQueryParam(
      'email_verification_required',
      emailVerificationRequired,
    ),
    ...booleanQueryParam('sign_up_enabled', signUpEnabled),
    ...booleanQueryParam('avoid_sdk_session', avoidSdkSession),
    ...booleanQueryParam('hide_copy', hideCopy),
    ...booleanQueryParam('customize-modal', modalCustomized),
    ...booleanQueryParam('consent_challenge', consentChallenge),
    ...booleanQueryParam('require_verification', requireVerification),
    ...(oauthParams?.responseType && {
      response_type: oauthParams.responseType,
    }),
    ...(oauthParams?.redirectType && {
      redirect_type: oauthParams.redirectType,
    }),
  });
  /* eslint-enable @typescript-eslint/naming-convention */

  return `${AUTH_DOMAIN_CORE}${AUTH_PATH_CORE}?${params}`;
};

export const getCoreAuthDomain = () => AUTH_DOMAIN_CORE;

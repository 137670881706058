export type TrekkieAttributes = Record<TrekkieDefaultAttribute, any>;

export type PageImpressionTracked = Record<LoginWithShopSdkPageName, boolean>;

export type TrekkieDefaultAttribute =
  | 'contentLanguage'
  | 'currency'
  | 'customerId'
  | 'isPersistentCookie'
  | 'isMerchantRequest'
  | 'microSessionCount'
  | 'microSessionId'
  | 'path'
  | 'referer'
  | 'shopId'
  | 'themeCityHash'
  | 'themeId'
  | 'uniqToken'
  | 'visitToken';

export enum MonorailSchema {
  UiImpression = 'shop_js_ui_impression/1.1',
  InstallmentsModalOpened = 'shop_pay_installments_dynamic_modal_impression/1.0',
  InstallmentsModalUserAction = 'shop_pay_installments_dynamic_modal_user_actions/1.0',
  InstallmentsBannerImpression = 'shop_pay_installments_banner_ui_impression/3.0',
  InstallmentsPrequalPopupPageImpression = 'shop_pay_installments_prequal_popup_page_impression/3.0',
  InstallmentsBannerPrequalInteraction = 'shop_pay_installments_banner_prequal_interaction/3.0',
  InstallmentsInvalidMetadata = 'shop_pay_installments_banner_invalid_metadata/1.0',
  ShopLoginFirstTimeRender = 'shop_js_ui_shop_login_first_time_render/1.0',
  ShopifyLoginWithShopSdkDiscountStatus = 'shopify_pay_login_with_shop_sdk_discount_status/2.0',
  LoginWithShopSdkPageImpression = 'shopify_pay_login_with_shop_sdk_page_impressions/3.2',
  LoginWithShopSdkUserAction = 'shopify_pay_login_with_shop_sdk_user_actions/2.1',
  LoginWithShopSdkErrorEvents = 'shopify_pay_login_with_shop_sdk_error_events/1.0',
  ShopifyPayModalStateChange = 'shopify_pay_modal_state_change/1.3',
  ShopifyPayPaymentPageUiInteraction = 'shopify_pay_payment_page_ui_interaction/1.1',
  LoginWithShopModalStateChange = 'shop_identity_modal_state_change/1.0',
}

export enum LoginWithShopSdkPageName {
  AuthorizeModal = 'AUTHORIZE_MODAL',
  ClassicCustomerAccount = 'CLASSIC_CUSTOMER_ACCOUNTS_ACCOUNT_PAGE',
  ClassicCustomerAccountCreateAccount = 'CLASSIC_CUSTOMER_ACCOUNTS_CREATE_ACCOUNT_PAGE',
  ClassicCustomerAccountLogin = 'CLASSIC_CUSTOMER_ACCOUNTS_LOGIN_PAGE',
  ComponentLoadedFollowing = 'COMPONENT_LOADED_FOLLOWING',
  ComponentLoadedNotFollowing = 'COMPONENT_LOADED_NOT_FOLLOWING',
  ContinueWithShop = 'CONTINUE_WITH_SHOP_PAGE',
  DiscountSaved = 'DISCOUNT_SAVE_CONFIRMATION_PAGE',
  DiscountShown = 'DISCOUNT_SHOWN',
  FollowButtonShownInViewport = 'FOLLOW_BUTTON_SHOWN_IN_VIEWPORT',
  FollowingGetShopAppCta = 'FOLLOWING_GET_SHOP_APP_CTA',
  PartnerEmailInputShown = 'PARTNER_EMAIL_INPUT_SHOWN',
  PhoneConsent = 'TEXT_MARKETING_SIGN_UP',
  PhoneConsentConfirmed = 'TEXT_MARKETING_CONFIRMED_PAGE',
  PhoneConsentDeclined = 'TEXT_MARKETING_DECLINED_PAGE',
  SdkLoaded = 'SDK_HAS_LOADED_INITIAL_PAGE',
  SignInWithShopButton = 'SIGN_IN_WITH_SHOP_BUTTON',
}

export enum LoginWithShopSdkUserAction {
  CreateAccountLinkClicked = 'CREATE_ACCOUNT_CLICK',
  EmailEntered = 'EMAIL_ENTERED',
  FollowOnShopClicked = 'FOLLOW_ON_SHOP_CLICKED',
  FollowingGetShopAppClick = 'FOLLOWING_GET_SHOP_APP_CLICK',
  PhoneConsentDeclined = 'DO_NOT_SHARE_PHONE_CLICK',
  PhoneConsentProvided = 'SHARE_PHONE_CLICK',
  SignInWithShopClicked = 'SIGN_IN_WITH_SHOP_BUTTON_CLICK',
  ThirdPartyFormSubmission = '3RD_PARTY_EMAIL_CAPTURE_FORM_SUBMISSION_CLICK',
}

export enum ShopifyPayModalState {
  Loaded = 'loaded',
  Shown = 'shown',
  Hidden = 'hidden',
}

export interface LoginWithShopSdkEventProps {
  apiKey?: string;
}

export interface LoginWithShopSdkPageImpressionEventProps
  extends LoginWithShopSdkEventProps {
  page: LoginWithShopSdkPageName;
  shopAccountUuid?: string;
}

export interface LoginWithShopSdkDiscountStatusEventProps
  extends LoginWithShopSdkEventProps {
  discountCode: string;
}

export interface LoginWithShopSdkErrorEventProps
  extends LoginWithShopSdkEventProps {
  apiKey: string;
  errorCode: string;
  errorMessage: string;
}

export interface LoginWithShopSdkUserActionEventProps
  extends LoginWithShopSdkEventProps {
  userAction: LoginWithShopSdkUserAction;
}

export interface ShopifyPayModalStateChangeProps {
  currentState: ShopifyPayModalState;
}
